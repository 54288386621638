import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import {Dropdown} from 'semantic-ui-react'

import ContactFormResultPopup from './ContactFormResultPopup'

import MenuToggleIcon from "../images/icon_menuToggle.png"
import FormButtonLoader from '../images/formButtonLoader.gif'


const halfWideStyles = `
  display: inline-block;
  width: 50%;
  
  &#firstNameContent, &#titleNameContent {
    padding-right: 2%;
  }
  
  &#lastNameContent, &#companyNameContent {
    padding-left: 2%;
  }
`
const Salutation = styled.p`
  width: 75%;
  margin: 0 auto;
  margin-bottom: 30px;
  
  span {
    display: none;
  }
`
const FormContent = styled.form`

`
const InputErrorMessage = styled.p`
    margin-top: .1rem;
    color: red;
    visibility: hidden;
`
const Label = styled.label`
  display: block;
  width: 100%;
  color: ${props => props.theme.blueDark};
  font-size: 1rem;
  font-weight: 900;
  padding-left: .5rem;
  margin-bottom: .25rem;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {

        font-size: 1.25rem;
        padding-left: 1rem;
        margin-bottom: .5rem;
    }
`
const Input = styled.input`
  width: 100%;
  border: none;
  background-color: ${props => props.theme.grayLight};
  border-radius: 4px;
  font-size: 1rem;
  padding: .5rem;
`
const LabelsInputsWrapper = styled.div`
    
    margin-bottom: .25rem;
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        &.halfWide {
            ${halfWideStyles}
        }
        margin-bottom: 1.5rem;
    }
    
    &.error {
        #firstName, #lastName, #title, #company, #email {
            border: 1px solid red;
        }
        ${InputErrorMessage} {
            visibility: visible;
        }
    }
`
const SubmitButton = styled.button`
  display: block;
  border: none;
  background-color: ${props => props.theme.blueMed};
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
  font-size: .85rem;
  padding: 1rem 4rem;
  margin-top: 20px;
  cursor: pointer;
  
  &.disabled {
    opacity: .25;
    background-repeat: no-repeat;
    background-size: auto 80%;
    background-position: center center;
    background-image: url(${FormButtonLoader});
    color: transparent;
  }
`
const Textarea = styled(Input)`
  height: 100px;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
        height: 150px;    
    } 
`

const InterestDropdown = styled.div`

  margin-bottom: 25px;
    
  .ui.selection.dropdown {
      background-color: ${props => props.theme.blueMed} ! important;
      color: white ! important;
      padding: 8px ! important;
      min-height: 1.5rem ! important;
      border: none ! important;
      
      i.dropdown.icon {
          padding: 0 !important;
          margin: 0  !important;
          opacity: 1 !important;
          border-radius: 50%;
          top: 0 !important;
          right: .5rem !important;
          width: 1.5rem !important;
          height: 100% !important;
          
          &:before {
              content: '' !important;
              display: block !important;
              position: relative !important;
              top: 0 !important;
              left: 0 !important;
              width: 100% !important;
              height: 100% !important;
              background-image: url(${MenuToggleIcon}) !important;
              background-size: 90% auto !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
              transition: transform 0.4s;
          }
      }
      
      .menu {
          background-color: ${props => props.theme.blueLight};
          margin-top: 4px;
          border: none !important;
          
          .item {
              color: ${props => props.theme.blueMed};
          }
      }
      
      &.active.visible {
          i.dropdown.icon {
              &:before {
                  transform: rotate(180deg);
              }
          }
      }
  }
`
const Container = styled.div`

    &.default {
        
        width: 90%;
    
        ${Salutation} {
            span:nth-child(1) {
                display: block;
                text-align: center;
                margin-bottom: 2vh;
            }
        }
        
        ${Input} {
        }
        ${Label} {
        }
        ${LabelsInputsWrapper} {
            // &#titleNameContent, &#companyNameContent {
            //     display: none;
            // }
        }
        
        ${FormContent} {
            width: 100%;
        }
        
        ${SubmitButton} {
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    &.solutions {
    
        width: 90%;
        
        ${Salutation} {
            span:nth-child(2) {
                display: block;
                text-align: center;
                margin-bottom: 2vh;
            }
        }
        ${Input} {
            &#title, &#company {
                display: inline-block;
              }
        }
        ${Label} {
            &#labelTitle, &#labelCompany {
                display: inline-block;
              }
        }
        ${InterestDropdown}, #search {
            display: none;
        }
        ${SubmitButton} {
            margin: 0 auto;
        }
    }
    
    &.contact {
        
        width: 100%;
    
        ${Salutation} {
            span:nth-child(3) {
                display: block;
            }
        }
        
        ${FormContent} {
            width: 100%;
            max-width: 560px;            
        }
        
        ${LabelsInputsWrapper} {
            // &#titleNameContent, &#companyNameContent {
            //     display: none;
            // }
        }
        
    }
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        &.default {
            width: 50%;
        }
        &.solutions {
            width: 50%;
        }
        &.contact {
            width: 70%;
        }
    }
`


class ContactForm extends React.Component {

    state = {
        loadedAgencyData: [],
        formTypes: ['default', 'solutions', 'contact'],
        formSubmitted: false,
        formCssStyle: 'default',
        firstName: '',
        firstNameError: false,
        lastName: '',
        lastNameError: false,
        email: '',
        emailError: false,
        title: '',
        titleError: false,
        company: '',
        companyError: false,
        search: this.props.searchTerms || [],
        comments: '',
        showPopupCallback: undefined,
        hidePopupCallback: undefined,
        successfulSubmission: false
    }

    componentDidMount = () => {

        // get list of categories from second sheet of excel file
        // NOTE: this page is where the order of the buttons on this page is determined
        let excelAgencyCategories = []
        for (let i = 0; i < this.props.data.allAgencydataXlsxSheet2.edges.length; i++) {
            if (this.props.data.allAgencydataXlsxSheet2.edges[i].node.contactformdropdown) excelAgencyCategories.push(this.props.data.allAgencydataXlsxSheet2.edges[i].node.contactformdropdown)
        }

        // process agency data and store into component state by setting up a promise
        // to resolve once process is complete
        new Promise((resolve, reject) => {

            this.setState(state => {

                state.loadedAgencyData = excelAgencyCategories

            }, () => {

                // wait for react callback to ensure state has been updated before proceeding
                this.setState({state: this.state})

                resolve()
            })

        }).then(() => {
            // add the first menu upon parsing off all data
            //this.buildCategoryButtons()
            console.log(this.state.loadedAgencyData)

        }).catch(error => {
            console.log(`Error processing excel data: ${error}`)
        })

        console.log(this.props)
        this.props.clearForm && this.props.clearForm(this.clearForm)
    }

    componentWillMount() {
        if (this.state.formTypes.includes(this.props.formType)) {
            this.setState({formCssStyle: this.props.formType})
        }
    }

    handleInputChange = (e) => {
        // console.log(e.target.value)
        this.setState({[e.target.name]: e.target.value})
    }

    handleDropdownChange = (e, selectionObj) => {
        console.log(selectionObj.value)
        this.setState({[selectionObj.name]: [selectionObj.value]})
    }

    doFormValidation = () => {
        let formValid = true;

        if (!this.state.firstName.match(/^[a-zA-Z.' -]+$/)) {
            this.setState({firstNameError: true})
            formValid = false;
        } else {
            this.setState({firstNameError: false})
        }

        if (!this.state.lastName.match(/^[a-zA-Z.' -]+$/)) {
            this.setState({lastNameError: true})
            formValid = false;
        } else {
            this.setState({lastNameError: false})
        }

        // found this email regex at: https://emailregex.com/
        if (!this.state.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            this.setState({emailError: true})
            formValid = false;
        } else {
            this.setState({emailError: false})
        }

        //if (this.props.formType === 'solutions') {
            if (!this.state.title.match(/^[a-zA-Z., -]+$/)) {
                this.setState({ titleError: true })
                formValid = false;
            } else {
                this.setState({ titleError: false })
            }

            if (!this.state.company.match(/^[a-zA-Z0-9., -]+$/)) {
                this.setState({ companyError: true })
                formValid = false;
            } else {
                this.setState({ companyError: false })
            }
        //}

        return formValid
    }

    getEmailJsonData = () => {

        // destructure the form values out of the state into correspondingly named variables
        const {firstName, lastName, title, company, email, search, comments} = this.state;
        // return an object with property names that match the names of the variables above
        return {firstName, lastName, title, company, email, search, comments}
    }

    clearForm = () => {

        this.setState(state => {
            state.formSubmitted = false
            state.firstName = ''
            state.firstNameError = false
            state.lastName = ''
            state.lastNameError = false
            state.email = ''
            state.emailError = false
            state.title = ''
            state.titleError = false
            state.company = ''
            state.companyError = false
            state.comments = ''
        }, () => {
            this.setState({state: this.state})
        })
    }

    doFormSubmit = (e) => {

        e.preventDefault()
        console.log(e)

        //script for testing php script for email functionality

        if ( this.doFormValidation() ) {

            const emailJson = this.getEmailJsonData();
            console.log(emailJson);

            this.setState({formSubmitted: true})

            fetch('email/mail.php', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',  // sent request
                    'Accept': 'application/json'   // expected data sent back
                },
                body: JSON.stringify(emailJson)
            })
              .then((response) => {
                  return response;
              })
              .then((myJson) => {

                  // const response = JSON.stringify(myJson)
                  console.log('Response status: ' + myJson.status);

                  this.setState(state => {

                      state.successfulSubmission = myJson.status == '200' ? true : false
                      if (myJson.status == '200') {
                          this.clearForm();
                      } else {
                          this.setState({formSubmitted: false})
                      }

                  }, () => {
                      this.state.showPopupCallback(4000);
                  })
              }).catch(error => {

                    console.log(error)
                    this.setState(state => {

                        state.formSubmitted = false

                    }, () => {
                        this.state.showPopupCallback(4000);
                    })
              });
        }
    }

    render() {
        console.log(this.state);
        return (
            <Container className={`${this.props.className} ${this.state.formTypes.includes(this.props.formType) ? this.props.formType : 'default'}`}>
                <Salutation>
                    <span>We're looking forward to connecting with you.</span>
                    <span>Thank you for taking the time to tell us more about your perfect fit. We're looking forward to connecting with you.</span>
                    <span>For a personalized approach to find the unique blend of agencies that fits your business needs, please contact our Client Engagement Team.</span>
                </Salutation>
                <FormContent onSubmit={this.doFormSubmit}>

                    <LabelsInputsWrapper id="firstNameContent" className={`halfWide${this.state.firstNameError ? " error" : ""}`}>
                        <Label htmlFor="firstName">First Name:</Label>
                        <Input onChange={this.handleInputChange} type="text" name="firstName" id="firstName" value={this.state.firstName}/>
                        <InputErrorMessage>First name is required</InputErrorMessage>
                    </LabelsInputsWrapper>

                    <LabelsInputsWrapper id="lastNameContent" className={`halfWide${this.state.lastNameError ? " error" : ""}`}>
                        <Label htmlFor="lastName">Last Name:</Label>
                        <Input onChange={this.handleInputChange} type="text" name="lastName" id="lastName" value={this.state.lastName}/>
                        <InputErrorMessage>Last name is required</InputErrorMessage>
                    </LabelsInputsWrapper>

                    <LabelsInputsWrapper id="titleNameContent"  className={`halfWide${this.state.titleError ? " error" : ""}`}>
                        <Label htmlFor="title" id="labelTitle">Title:</Label>
                        <Input onChange={this.handleInputChange} type="text" name="title" id="title" value={this.state.title}/>
                        <InputErrorMessage>Title is required</InputErrorMessage>
                    </LabelsInputsWrapper>

                    <LabelsInputsWrapper id="companyNameContent"  className={`halfWide${this.state.companyError ? " error" : ""}`}>
                        <Label htmlFor="company" id="labelCompany">Company:</Label>
                        <Input onChange={this.handleInputChange} type="text" name="company" id="company" value={this.state.company}/>
                        <InputErrorMessage>Company is required</InputErrorMessage>
                    </LabelsInputsWrapper>

                    <LabelsInputsWrapper id="emailNameContent" className={`${this.state.emailError ? "error" : ""}`}>
                        <Label htmlFor="email">Email:</Label>
                        <Input onChange={this.handleInputChange} type="email" name="email" id="email" value={this.state.email}/>
                        <InputErrorMessage>Email is required</InputErrorMessage>
                    </LabelsInputsWrapper>

                    <Label id="search">I am interested in:</Label>
                    <InterestDropdown>
                        <Dropdown fluid selection upward={false}
                                  onChange={this.handleDropdownChange}
                                  name='search'
                                  options={
                                      this.state.loadedAgencyData.length > 0 ? this.state.loadedAgencyData.map(item => {
                                          return {
                                              key: item,
                                              text: item,
                                              value: item,
                                          }
                                      }) : []
                                  }
                                  placeholder={'Select...'}
                        />
                    </InterestDropdown>

                    <Label htmlFor="comments">How can we help you?</Label>
                    <Textarea onChange={this.handleInputChange} as="textarea" name="comments" value={this.state.comments} placeholder="Tell us how we could make a difference for you, including details about your business needs."/>

                    <SubmitButton type='submit' className={this.state.formSubmitted ? 'disabled' : ''} disabled={this.state.formSubmitted}>Send</SubmitButton>
                </FormContent>
                <ContactFormResultPopup success={this.state.successfulSubmission} getShowCallback={(callback) => this.setState({showPopupCallback:callback})} getHideCallback={(callback) => this.setState({hidePopupCallback:callback})} />

            </Container>
        )
    }
}

// using the StaticQuery component which allows us to do graphql
// queries in components. This allows us to emncapsulate the graphgl
// excel data call in this component rather than in a page js file
export default ({className, formType, searchTerms, clearForm}) => (
  <StaticQuery
    query={graphql`
      query {
        allAgencydataXlsxSheet2 {
          edges {
            node {
              contactformdropdown
            }
          }
        }
      }
    `}
    render={data => (
      <ContactForm {...{className, formType, searchTerms, clearForm, data}} />
    )}
  />
)
