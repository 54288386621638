import React from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'


const Footer = styled.footer`
  padding: ${props => props.theme.paddingSidesMobile} ${props => props.theme.paddingSidesMobile};
  background-color: #fff;

  li {
    list-style-type: none;
  }
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    padding: 4rem ${props => props.theme.paddingSidesDesktop} 1rem ${props => props.theme.paddingSidesDesktop};
  }
`
const Divider = styled.hr`
  background-color: ${props => props.theme.blueMed};
  color: ${props => props.theme.blueMed};
  height: 2px;
  border: none;
`
const MainLinks = styled.ul`

  margin-bottom: .85rem;

  li, a {
    color: ${props => props.theme.grayDark};
    text-transform: uppercase;
    font-size: .85rem;
  }
  
  li {
    margin-bottom: 15px;
  }
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    display: flex;
    
    li {
      padding-right: 2rem;
      margin-bottom: 0;
    }
  }
`
const SecondaryLinks = styled.ul`

  margin-top: 15px;

  li, a {
    color: ${props => props.theme.blueMed};
    font-size: .85rem;
  }
  
  li {
    margin-bottom: 15px;
  }
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    display: flex;
    margin-top: 0;
    
    li {
      padding-right: 2rem;
      margin-bottom: 0;
    }
  }
`

class MainFooter extends React.Component {

  render() {

    return (
      <Footer>
        <div>
          <MainLinks>
            <li><Link to='/'>HOME</Link></li>
            <li><Link to='/pharmaceutical-advertising-agencies'>OUR AGENCIES</Link></li>
            <li><Link to='/healthcare-mission-culture'>FIND AN AGENCY</Link></li>
            <li><Link to='/pharmaceutical-awards'>Awards</Link></li>
            <li><Link to='/innovations-&-insights'>Innovations & Insights</Link></li>
            {/* <li><Link to='/contact-an-expert'>Meet An Expert</Link></li> */}
          </MainLinks>
          <Divider/>
          <SecondaryLinks>
            <li>&#169; 2022 Interpublic Group</li>
            <li><a href="https://www.interpublic.com/" target="_blank" rel='noopener noreferrer'>Interpublic Group Corporate</a></li>
            <li><a href="https://www.interpublic.com/terms-of-use" target="_blank" rel='noopener noreferrer'>Terms of Use</a></li>
            <li><a href="https://www.interpublic.com/privacy-notice" target="_blank" rel='noopener noreferrer'>Privacy Notice</a></li>
            <li><a href="https://www.interpublic.com/gdpr/" target="_blank" rel='noopener noreferrer'>GDPR Guidance</a></li>
          </SecondaryLinks>
        </div>
      </Footer>
    )
  }
}

export default MainFooter