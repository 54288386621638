import React from 'react'
import styled from 'styled-components'
// import { Tween, Timeline } from 'react-gsap';

const PopupWrapper = styled.div`
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  
  opacity: 0;
  pointer-events: none;
  
  transition: opacity .25s;
  
  &.show {
    opacity: 1;
    pointer-events: all;
  }
`
const Popup = styled.div`
  width: 90%;
  max-width: 500px;
  text-align: center;
`
const PopupHeadline = styled.h1`
  font-size: 3rem;
  font-weight: 300;
  color: ${props => props.theme.blueDark}
`
const PopupSubhead = styled.h2`
  font-size: 1.5rem;
  font-weight: 900;
  color: ${props => props.theme.blueDark} 
`
const PopupBodyCopy = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${props => props.theme.blueDark} 
`

class ContactFormResultPopup extends React.Component {

    state = {
        show: false,
        timer: undefined
    }

    componentDidMount() {

        this.props.getShowCallback(this.showPopup)
        this.props.getHideCallback(this.hidePopup)
        this.setState({timer: this.getTimeout()})
    }

    componentWillUnmount() {

        clearTimeout(this.state.timer)
    }

    getTimeout = () => (autoHideTime) => {

        return setTimeout(() => {

           this.hidePopup()

        }, autoHideTime)
    }

    showPopup = (autoHideTime) => {

        this.setState(state => {

            state.show = true
            if (autoHideTime) {
                state.timer = this.state.timer(autoHideTime)
                console.log(this.state.timer)
            }

        }, () => {
            this.setState({state: this.state})
        })

    }

    hidePopup = () => {

        clearTimeout(this.state.timer)

        this.setState(state => {
            state.timer = this.getTimeout()
            state.show = false
        }, () => {
            this.setState({state: this.state})
        })
    }

    render() {

        return (
                <PopupWrapper className={this.state.show ? 'show' : ''}>
                    <Popup>
                        {
                            this.props.success ?
                             <>
                                <PopupHeadline>Thank You!</PopupHeadline>
                                <PopupSubhead>Your information has been sent.</PopupSubhead>
                                <PopupBodyCopy>Someone from our Client Engagement Team will contact you soon.</PopupBodyCopy>
                            </>
                                :
                            <>
                                <PopupHeadline>Oh oh!!</PopupHeadline>
                                <PopupSubhead>There was a problem submitting your inquiry.</PopupSubhead>
                                <PopupBodyCopy>We're very sorry :( Please try again later.</PopupBodyCopy>
                            </>
                        }
                    </Popup>
                </PopupWrapper>
            )
        }
    }

export default ContactFormResultPopup