import React from "react";
import {Helmet} from 'react-helmet'

import ipgLogo from '../images/logo_ipg.png'


const urlRoot = 'https://health.interpublic.com'

const defaultMetaData = {
    title: 'Healthcare Advertising Network',
    canonical: `${urlRoot}`,
    description: "We are a global network of best-in-class advertising agencies that work independently or in teams on award-winning campaigns for healthcare professionals, consumers, and patients.",
    ogTitle: "Healthcare Advertising Network",
    ogDescription: "We are a global network of best-in-class advertising agencies that work independently or in teams on award-winning campaigns for healthcare professionals, consumers, and patients.",
    ogImage: ipgLogo
}

const metaData = [

    {
        title: `${defaultMetaData.title}`,
        canonical: `${urlRoot}`,
        description: `${defaultMetaData.description}`,
        ogTitle: `${defaultMetaData.title}`,
        ogDescription: `${defaultMetaData.description}`,
    },
    {
        title: "Healthcare Advertising Network | Mission and Culture",
        canonical: `${urlRoot}/healthcare-mission-culture`,
        description: "IPG HEALTH is a global network of 75+ healthcare agencies focused on making a difference in the lives of our clients and the people they serve in 25 countries.",
        ogTitle: "Healthcare Advertising Network | Mission and Culture",
        ogDescription: "IPG HEALTH is a global network of 75+ healthcare agencies focused on making a difference in the lives of our clients and the people they serve in 25 countries.",
    },
    {
        title: "Healthcare Advertising Network | Pharmaceutical Advertising Agencies",
        canonical: `${urlRoot}/pharmaceutical-advertising-agencies`,
        description: "IPG Health is a global network of communication, marketing, and advertising agencies specializing in all areas of healthcare. We offer clients our depth and breadth of experience and talent in every market.",
        ogTitle: "Healthcare Advertising Network | Pharmaceutical Advertising Agencies",
        ogDescription: "IPG Health is a global network of communication, marketing, and advertising agencies specializing in all areas of healthcare. We offer clients our depth and breadth of experience and talent in every market."
    },
    {
        title: "Healthcare Advertising Network | Pharmaceutical Advertising Awards",
        canonical: `${urlRoot}/pharmaceutical-awards`,
        description: "At IPG Health, we live and breathe the big ideas. Ideas that challenge current thinking and make a real difference for our clients' businesses—and for which our agencies gather the most highly-coveted honors, year after year.",
        ogTitle: "Healthcare Advertising Network | Pharmaceutical Advertising Awards",
        ogDescription: "At IPG Health, we live and breathe the big ideas. Ideas that challenge current thinking and make a real difference for our clients' businesses—and for which our agencies gather the most highly-coveted honors, year after year."
    },
    {
        title: "Healthcare Advertising Network | Contact Us",
        canonical: `${urlRoot}/healthcare-contact-us`,
        description: "Contact us to experience the difference",
        ogTitle: "Healthcare Advertising Network | Contact Us",
        ogDescription: "Contact us to experience the difference"
    },
    {
        title: "Healthcare Advertising Network | Page Not Found",
        description: "Page Not Found",
    },
]

export default ({metaDataIndex}) => {

    const metaDataObj = (() => {

        if (metaDataIndex == undefined) {

            return {
                title:defaultMetaData.title,
                canonical:defaultMetaData.canonical,
                description:defaultMetaData.description,
                ogTitle:defaultMetaData.ogTitle,
                ogDescription:defaultMetaData.ogDescription,
                ogImage:defaultMetaData.ogImage,
            }

        } else {
            return {
                title:metaData[metaDataIndex - 1].title || defaultMetaData.title,
                canonical:metaData[metaDataIndex - 1].canonical || defaultMetaData.canonical,
                description:metaData[metaDataIndex - 1].description || defaultMetaData.description,
                ogTitle:metaData[metaDataIndex - 1].ogTitle || defaultMetaData.ogTitle,
                ogDescription:metaData[metaDataIndex - 1].ogDescription || defaultMetaData.ogDescription,
                ogImage:metaData[metaDataIndex - 1].ogImage || defaultMetaData.ogImage,
            }
        }
    })()

    return (
      <Helmet>
          <html lang="en"/>
          <title>{metaDataObj.title}</title>
          <link rel="canonical" href={metaDataObj.canonical}/>
          <meta name="description" content={metaDataObj.description}/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content={metaDataObj.ogTitle}/>
          <meta property="og:description" content={metaDataObj.ogDescription}/>
          <meta property="og:image" content={metaDataObj.ogImage}/>
      </Helmet>
    )
}