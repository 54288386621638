import React from 'react'
import styled from 'styled-components'
import {ThemeProvider, createGlobalStyle} from 'styled-components'

import 'semantic-ui-css/semantic.min.css'

import MainNav from './MainNav'
import MainFooter from './MainFooter'
import MetaData from './MetaData'


const globalCssValues = {
  sitePaddingTopMobile: '75px',
  paddingTopMobile: '30px',
  paddingSidesMobile: '25px',
  paddingTopDesktop: '20px',
  paddingSidesDesktop: '60px',

  blueLight: '#dbeeff',
  blueMed: '#0079c1',
  blueDark: '#003767',
  grayLight: '#eaeef1',
  grayMed: '#888888',
  grayDark: '#6a737b',

  breakpointXLDesktop: '2500px',
  breakpointLargeDesktop: '1440px',
  breakpointDesktop: '1024px',
  breakpointTablet: '768px',
}

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/Avenir-Light.ttf) format('truetype'),
        url('/fonts/Avenir-Light.woff2') format('woff2'),
        url('/fonts/Avenir-Light.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Avenir-Roman.ttf) format('truetype'),
        url('/fonts/Avenir-Roman.woff2') format('woff2'),
        url('/fonts/Avenir-Roman.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(/fonts/Avenir-Black.ttf) format('truetype'),
        url('/fonts/Avenir-Black.woff2') format('woff2'),
        url('/fonts/Avenir-Black.woff') format('woff');
  }
  
  body {
    font-family: 'Avenir', sans-serif !important;
    font-weight: 400;
    background-color: #001b33;
  }
  
  h1, h2, h3, h4, h5 {
    font-family: 'Avenir', sans-serif !important;
  }
  
  button, input, optgroup, select, textarea {
    font-family: 'Avenir', sans-serif !important;
  }
  
  ul {
    margin: 0;
    padding: 0;
  }
  
  button:focus {
    outline: none
  }
  
  .bannerHeader {
    position: relative;
    display: inline-block;
    color: white;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.1;
    text-transform: uppercase;
    padding: .8rem 0 .9rem 0;
    padding-left: ${globalCssValues.paddingSidesMobile};
    padding-right: ${globalCssValues.paddingSidesMobile};
    left: -${props => props.theme.paddingSidesMobile};
    
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0179c1+0,78bde8+100 */
    background: #0179c1; /* Old browsers */
    background: -moz-linear-gradient(left,  #0179c1 0%, #78bde8 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #0179c1 0%,#78bde8 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #0179c1 0%,#78bde8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0179c1', endColorstr='#78bde8',GradientType=1 ); /* IE6-9 */
    
    @media (min-width: ${globalCssValues.breakpointTablet}) {
      padding-left: ${globalCssValues.paddingSidesDesktop};
      padding-right: ${globalCssValues.paddingSidesDesktop};
      left: -${globalCssValues.paddingSidesDesktop};
      font-size: 3rem;
    }
  }
  
  .ui.selection.dropdown .menu {
    @media only screen and (min-width: 992px) {
        max-height: 10.68571429rem !important;
    }
  }
  
  .ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    color: white !important;
  }
`

const PageContainer = styled.div`  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    padding-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${props => props.theme.breakpointXLDesktop}) {
    max-width: 144rem;
    margin-left: auto;
    margin-right: auto;
  }

  .skip-to-content-link {
    left: 30%;
    top: 2rem;
    position: absolute;
    transform: translateY(-3000px);
    z-index: 2000;
    padding: 1rem 2rem;
    color: #fff;
    background-color: #0079c1;
  
    &:focus {
      transform: translateY(0%);
    }
  }
`

class PageTemplate extends React.Component {

  render() {

    return (
      // The ThemeProvider requires that is wrap a single child element
      // which is why we use the jsx fragments below
      <ThemeProvider theme={globalCssValues}>
        <>
          <MetaData metaDataIndex={this.props.metaDataIndex} />
          <GlobalStyles />
          <PageContainer>
            <a href="#main" class="skip-to-content-link btn btn-primary">Skip to content</a>
            <MainNav selectedNavIndex={this.props.selectedNavIndex} />
            {this.props.children}
            <MainFooter />
          </PageContainer>
        </>
      </ThemeProvider>
    )
  }
}

export default PageTemplate