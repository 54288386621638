import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import ContactFormOverlay from './ContactFormOverlay'

import LogoImg from '../images/logo_ipg.png'
import HamburgerIconOpen from '../images/icon_hamburger_open.png'
import HamburgerIconClose from '../images/icon_hamburger_close.png'


const Logo = styled(Link)`
  display: flex;
  position: relative;
  z-index: 1000;
  width: 150px;
  cursor: default;
  
  img {
    width: 3.625rem;
    height: auto;
  }
  
  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    width: 19.8vw;
    top:0;

    img {
      width: 94px;
    }
  }
`

const Tagline = styled.span`
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  position: absolute;
  top: 0.1rem;
  left: 5rem;
  color: ${props => props.theme.blueMed};
  text-transform: uppercase;
  letter-spacing: .05rem;
  width: 100%;
  
  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    top: 0.7rem;
    left: 7.5rem;
    width: 50%;
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    width: 25rem;
    top: 1.1rem;
    left: 8rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    
  }

`

const LinksContainer = styled.div`
  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    justify-items: right;
    position: absolute;
    top: 30px;
    right: ${props => props.theme.paddingSidesDesktop};
    max-width: 60%;
  }
`
const MainLinks = styled.ul`
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  list-style-type: none;
  padding-top: 60px;
  padding-left: ${props => props.theme.paddingSidesMobile};
  padding-right: ${props => props.theme.paddingSidesMobile};
  overflow: hidden;  
  
  li {
    padding-top: 30px;
  }
  
  a {
    display: block;
    color: ${props => props.theme.grayDark};
    font-size: 1.75rem;
    text-transform: uppercase;
    
    &.selected {
      text-decoration: underline;
      color: ${props => props.theme.blueMed}
    }
  }
  
  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    position: relative;
    display: flex;
    top: 0;
    width: auto;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
    background-color: transparent;
    padding-top: 0.4rem;
    
    li {
      padding-top: 0;
    }
    
    a {
      font-size: 1rem;
      padding-right: 1rem;
      text-align: center;
    }
    
    .contact {
      display: none;
    }
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    a {
      padding-right: 3rem;
    }
  }
`
const SecondaryLinks = styled.ul`
  display: none;
  list-style-type: none;
  
  a {
    color: ${props => props.theme.blueMed};
    text-transform: uppercase;
  }
  
  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    display: block;
    display: flex;
    align-items: center;
    
    a {
      font-size: .9rem;
      white-space: nowrap;
    }
  }
`
const EmailButton = styled.button`
  background-color: ${props => props.theme.blueMed};
  border-radius: 3px;
  border: none;
  color: white;
  text-transform: uppercase;
  padding: 1rem 2rem .9rem 2rem;
  // margin-right: 2rem;
  margin-right: 2.1vw;
  font-size: .85rem;
  cursor: pointer;

  :focus {
  
    /* Adds a white border around the button, along with a blue glow. The white and blue have a color contrast ratio of at least 3:1, which means this will work against any background color. */
    box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;
    
    /* NOTE: box-shadow is invisible in Windows high-contrast mode, so we need to add a transparent outline, which actually does show up in high-contrast mode. */
    outline: 2px dotted transparent;
    outline-offset: 2px;
  }
`

const Hamburger = styled.button`
  display: block;
  position: absolute;
  top: ${props => props.theme.paddingTopMobile};
  right: ${props => props.theme.paddingSidesMobile};
  width: 22px;
  height: 22px;
  border: none;
  background-image: url(${HamburgerIconOpen});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-color: transparent;
  
  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    display: none;
  }
`
const Nav = styled.nav`
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: auto;
  min-height: 0%;
  padding-top: ${props => props.theme.paddingTopMobile};
  padding-left: ${props => props.theme.paddingSidesMobile};
  padding-right: ${props => props.theme.paddingSidesMobile};
  padding-bottom: ${props => props.theme.paddingSidesMobile};
  background-color: white;
  
  transition: min-height .5s;
  
  @media (min-width: 769px) {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    // margin-top: ${props => props.theme.paddingTopDesktop};
    padding-left: ${props => props.theme.paddingSidesDesktop};
    padding-right: ${props => props.theme.paddingSidesDesktop};
    background-color: white;
  }
  
  &.open {
  
    min-height: 100%;
    
    ${Hamburger} {
        background-image: url(${HamburgerIconClose});
        background-size: 75% auto;
    }
    
    ${MainLinks} {
      top: 1rem;
    }
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    max-width: 1440px;
  }

  @media (min-width: ${props => props.theme.breakpointXLDesktop}) {
    max-width: 144rem;
  }
`


class MainNav extends React.Component {

  state = {
    mobileNavOpen: false,
    overlayShowCallback: null,
  }

  doHamburgerClick = () => {
    this.setState({mobileNavOpen: !this.state.mobileNavOpen})
  }

  doShowEmailOverlay = () => {
    this.state.overlayShowCallback(true)
  }

  render() {

    return (
      <Nav className={this.state.mobileNavOpen ? 'open' : 'closed'} role="heading" aria-level="1">
        <Logo><img src={LogoImg} alt='IPG Health logo' /><Tagline>Experience The&nbsp;Difference</Tagline></Logo>
        <LinksContainer>
          <MainLinks>
            <li><Link className={this.props.selectedNavIndex == 1 ? 'selected' : ''} to='/'>HOME</Link></li>
            <li><Link className={this.props.selectedNavIndex == 3 ? 'selected' : ''} to='/pharmaceutical-advertising-agencies'>OUR AGENCIES</Link></li>
            <li><Link className={this.props.selectedNavIndex == 2 ? 'selected' : ''} to='/healthcare-mission-culture'>FIND AN AGENCY</Link></li>
            <li><Link className={this.props.selectedNavIndex == 4 ? 'selected' : ''} to='/pharmaceutical-awards'>Awards</Link></li>
            <li><Link className={this.props.selectedNavIndex == 5 ? 'selected' : ''} to='/innovations-&-insights'>Innovation & Insights</Link></li>
            <li className={"contact"}><EmailButton onClick={this.doShowEmailOverlay}>Email</EmailButton></li>
            <li className={"contact"}><a href="tel:212-704-1200">Tel: 212-704-1200</a></li>
          </MainLinks>
          <SecondaryLinks>
            <li><EmailButton onClick={this.doShowEmailOverlay}>Email</EmailButton></li>
            <li><a href="tel:212-704-1200">Tel: 212-704-1200</a></li>
          </SecondaryLinks>
        </LinksContainer>
        
        <Hamburger onClick={this.doHamburgerClick} title="Menu Button"></Hamburger>

        <ContactFormOverlay
            setShowCallback={(callback) => {
              this.setState(state => {
                state.overlayShowCallback = callback
              }, () => {

              });
            }}
        />

      </Nav>
    )
  }
}

export default MainNav
