import React from 'react'
import styled from 'styled-components'

import ContactForm from './ContactForm'

import CloseButtonIcon from '../images/agencies/agencies_icon_closeButton_dsktp.png'


const OverlayContainer = styled.div`
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #78bde8;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0 25px 0;
    overflow: scroll;
    
    h1 {
        font-weight: 900;
        font-size: 1.75rem;
        color: ${props => props.theme.blueDark};
        margin: 0;
        margin-bottom: 2vh;
        line-height: normal;
        min-height: auto;
        text-align: center;
        width: 70%;
    }
    
    &.show {
        display: flex;
    }
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        
        justify-content: center;
        padding: 50px 0;
        
        h1 {
            font-size: 3rem;
            width: auto;
        }
    }
    
    @media (max-height: 700px) {
        justify-content: start;
    }
`

const CloseButton = styled.button`
    position: fixed;
    border: none;
    background-color: transparent;
    width: 30px;
    height: 30px;
    top: 15px;
    right: 30px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    background-image: url(${CloseButtonIcon});
    cursor: pointer;
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        
        width: 40px;
        height: 40px;
    }
`

class ContactFormOverlay extends React.Component {

    state = {
        show: false,
        doClearForm: undefined,
    }

    componentWillMount() {
        this.props.setShowCallback(this.doShowEmailOverlay)
    }

    doShowEmailOverlay = showIt => {
        this.setState({show:showIt})
        !showIt && this.state.doClearForm()
    }

    render() {

        return (
            <OverlayContainer className={this.state.show && 'show'}>
                <h1>Ready to experience the difference?</h1>
                <CloseButton onClick={() => this.doShowEmailOverlay(false)}></CloseButton>
                <ContactForm clearForm={(clearFormCb) => {this.setState({doClearForm: clearFormCb})}} formType={this.props.formType} searchTerms={this.props.searchTerms} />
            </OverlayContainer>
        )
    }
}

export default ContactFormOverlay
